/* eslint-disable max-lines-per-function */
import { Injectable } from "@angular/core";

import
{
  Observable, catchError
} from "rxjs";

import
{
  APIService, unwrapResponse
} from "./api.service";

import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";

import
  {
    DocumentUpdate,
    EPSProtocol, Protocol
  } from "../models";
import { ReportingTypeEnum } from "../enums";

@Injectable({
  providedIn: "root"
})


export class ProtocolService
{
  inConnectedMode = false;

  private handleError: ErrorHandlerCreator;


  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("ProtocolService");
  }

  insertProtocol(newProtocol: Protocol, newDocuments?: DocumentUpdate[])
    : Observable<boolean | null>
  {
    if ((newProtocol as EPSProtocol).ReportingType == ReportingTypeEnum.System)
    {
      return this.api.post<boolean | null>("Intervention/UpdateIntervention", newProtocol).pipe(
        unwrapResponse(),
        catchError(this.handleError("sendProtocol", null))
      );
    }
    else
    {
      const fd: FormData = new FormData();

      fd.append("interventionJson", JSON.stringify(newProtocol));

      let i = 0;

      newDocuments?.forEach(document =>
      {
        if (document.File != null)
        {
          fd.append("documents[" + i + "].Description", document.Description? document.Description:"");
          fd.append("documents[" + i + "].TypeID", document.TypeID);
          fd.append("documents[" + i + "].File", document.File, document.FileName);
        }
        i++;
      });


      return this.api.post<boolean | null>("Intervention/UpdateInterventionDocuments", fd).pipe(
        unwrapResponse(),
        catchError(this.handleError("sendProtocol", null))
      );
    }
  }
}